<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />

    <v-col cols="12">
      <v-card-title style="background-color: transparent !important" class="">
        <h3></h3>
      </v-card-title>
      <v-tabs
        class=""
        fixed-tabs
        background-color="transparent"
        v-model="tab"
        :vertical="deviceType == 'mobile' ? true : false"
      >
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          شیفت
        </v-tab>
        <v-tab
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          پزشک
        </v-tab>
        <v-tab
          v-if="role != 'reception' && role != 'dentistryNurse'"
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          پرستار و پیراپزشک
        </v-tab>
        <v-tab
          v-if="role != 'reception' && role != 'dentistryNurse'"
          class="tab-title"
          :style="deviceType == 'mobile' ? 'border-radius: 0 !important' : ''"
        >
          کارمند پذیرش
        </v-tab>
      </v-tabs>

      <v-card class="br-card">
        <v-card class="pa-2">
          <v-tabs-items v-model="tab">
            <!-- شیفت -->
            <v-tab-item>
              <mobile-text v-if="deviceType == 'mobile'" :title="''" />
              <v-card v-else>
                <v-card-text>
                  <div>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateFrom">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateFrom"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateFrom"
                          element="dateFrom"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateTo">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateTo"
                            label=" تاریخ تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateTo"
                          element="dateTo"
                          color="#00a7b7"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeFrom">
                          <v-text-field
                            outlined
                            dense
                            append-icon="schedule"
                            v-model="timeFrom"
                            label=" ساعت از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeFrom"
                          element="timeFrom"
                          color="#00a7b7"
                          type="time"
                        /> </v-col
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeTo">
                          <v-text-field
                            outlined
                            dense
                            append-icon="schedule"
                            v-model="timeTo"
                            label=" ساعت تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeTo"
                          element="timeTo"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          class="primary-btn submit-btn"
                          style="float: right"
                          @click="shiftList()"
                          :disabled="Busy"
                          >اعمال</v-btn
                        >
                      </v-col>
                    </v-row>
                    <hr />
                    <v-text-field
                      v-model="Filter"
                      prepend-inner-icon="mdi-magnify"
                      label="جستجو"
                      single-line
                      hide-details
                      clearable
                      filled
                      rounded
                      class="text-right w-80 mb-2 search-input"
                    ></v-text-field>
                    <!-- shift report => shifts table  -->
                    <b-table
                      responsive
                      striped
                      show-empty
                      :fields="shiftsListFields"
                      :items="shiftsList"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                      :busy="Busy"
                      :filter="Filter"
                      :current-page="CurrentPage"
                      :per-page="PerPage"
                      @filtered="onFiltered"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                        </div>
                      </template>
                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            class="primary-btn pa-2"
                            :to="
                              '/' +
                              (role == 'reception'
                                ? 'reception'
                                : role == 'dentistryNurse'
                                ? 'dentistryNurse'
                                : 'admin') +
                              '/shiftReport/' +
                              data.item.id
                            "
                            target="_blank"
                            style="width: 100%"
                            ><v-icon> visibility</v-icon></v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <v-pagination
                      v-model="CurrentPage"
                      :length="Math.ceil(TotalRows / PerPage)"
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                    ></v-pagination>
                    <v-select
                      label="تعداد در هر صفحه:"
                      style="width: 150px"
                      v-model="PerPage"
                      :items="perPageOptions"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- پزشک -->
            <v-tab-item>
              <v-card>
                <v-card-title
                  style="font-size: 18px !important"
                  class="mb-2 primary--text"
                >
                  گزارش خدمات پزشکان
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-row v-if="role == 'manager'" class="align-items-center">
                      <v-icon class="text-danger mb-5"
                        >mdi-alert-decagram-outline
                      </v-icon>
                      <p class="text-danger ms-1">
                        بازه زمانی انتخابی نمی‌تواند بیشتر از 25 ساعت باشد
                      </p>
                    </v-row>
                    <v-row
                      v-if="role == 'reception'"
                      class="align-items-center"
                    >
                      <v-icon class="text-danger mb-5 me-1" v-if="deviceType != 'mobile'"> mdi-alert-decagram-outline </v-icon>
                      <p class="text-danger">
                        بازه زمانی انتخابی نمی‌تواند بیشتر از ۱۳ ساعت باشد.
                      </p>
                    </v-row>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateFrom1">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateFrom1"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateFrom1"
                          element="dateFrom1"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateTo1">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateTo1"
                            label=" تاریخ تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateTo1"
                          element="dateTo1"
                          color="#00a7b7"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeFrom4">
                          <v-text-field
                            outlined
                            dense
                            append-icon="schedule"
                            v-model="timeFrom"
                            label=" ساعت از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeFrom"
                          element="timeFrom4"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeTo4">
                          <v-text-field
                            outlined
                            dense
                            append-icon="schedule"
                            v-model="timeTo"
                            label=" ساعت تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeTo"
                          element="timeTo4"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div v-if="role == 'dentistryNurse'">
                          <v-autocomplete
                            v-if="!Busy"
                            clearable
                            outlined
                            dense
                            v-model="drName"
                            :items="
                              drNames.filter((x) => x.expertise == 'دندانپزشک')
                            "
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                          ></v-autocomplete>
                        </div>
                        <div v-else>
                          <v-autocomplete
                            v-if="!Busy"
                            clearable
                            outlined
                            dense
                            v-model="drName"
                            :items="
                              drNames.filter((x) => x.expertise != 'دندانپزشک')
                            "
                            item-text="text"
                            item-value="id"
                            label="نام پزشک"
                            class="pa-0"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          class="primary-btn submit-btn"
                          style="float: right"
                          :disabled="!drName"
                          @click="docReport"
                          >اعمال</v-btn
                        >
                      </v-col>
                    </v-row>

                    <hr v-if="docLoaded" />
                    <v-row v-if="docLoaded">
                      <v-col>
                        <v-btn
                          class="secondary-btn"
                          @click="printReport('docReport')"
                          :disabled="doctorComputedDisabled"
                          v-if="deviceType != 'mobile'"
                          >پرینت</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          class="secondary-btn"
                          style="float: left"
                          @click="$refs.docExcel.$el.click()"
                          :disabled="doctorComputedDisabled"
                          >دریافت اکسل خدمات</v-btn
                        >
                        <vue-excel-xlsx
                          v-show="false"
                          ref="docExcel"
                          :data="excelDocItems"
                          :columns="
                            role == 'reception' ? recepExcel : adminExcel
                          "
                          :filename="'گزارش خدمات'"
                          :sheetname="currentDate"
                        >
                          دریافت اکسل
                        </vue-excel-xlsx>
                      </v-col>
                    </v-row>
                    <!--<hr />-->
                    <div v-if="docBusy" class="text-center pa-10">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <h5 class="mt-4 grey--text text--darken-1">
                        در حال بارگذاری...
                      </h5>
                    </div>
                    <div v-else>
                      <report
                        id="docReport"
                        v-if="docLoaded"
                        :items="docReportItems"
                        :job="'doctor'"
                        :isPrinting="isPrinting"
                        :deviceType="deviceType"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!--پرستار و پیراپزشک -->
            <v-tab-item>
              <v-card>
                <v-card-title
                  style="font-size: 18px !important"
                  class="mb-2 primary--text"
                >
                  گزارش خدمات پرستاران
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateFrom2">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateFrom2"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateFrom2"
                          element="dateFrom2"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateTo2">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateTo2"
                            label=" تاریخ تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateTo2"
                          element="dateTo2"
                          color="#00a7b7"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeFrom2">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="timeFrom"
                            label=" ساعت از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeFrom"
                          element="timeFrom2"
                          color="#00a7b7"
                          type="time"
                        /> </v-col
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeTo2">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="timeTo"
                            label=" ساعت تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeTo"
                          element="timeTo2"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-if="!Busy"
                          outlined
                          dense
                          v-model="nurseName"
                          :items="nurseNames"
                          item-text="text"
                          item-value="id"
                          label="نام پرستار"
                          class="pa-0"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          class="submit-btn primary-btn"
                          style="float: right"
                          @click="nurseReport"
                          :disabled="nurseName == ''"
                          >اعمال</v-btn
                        >
                      </v-col>
                    </v-row>
                    <hr v-if="nurseLoaded" />
                    <v-row v-if="role == 'manager'" class="align-items-center">
                      <v-icon class="text-danger mb-5"> priority_high </v-icon>
                      <p class="mt-2 ms-2 me-2 text-danger">
                        بازه زمانی انتخابی نمی‌تواند بیشتر از 25 ساعت باشد
                      </p>
                    </v-row>

                    <v-row v-if="nurseLoaded">
                      <v-col>
                        <v-btn
                          class="secondary-btn"
                          @click="printReport('nurseReport')"
                          :disabled="nurseComputedDisabled"
                          v-if="deviceType != 'mobile'"
                          >پرینت</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          @click="$refs.nurseExcel.$el.click()"
                          class="secondary-btn"
                          style="float: left"
                          :disabled="nurseComputedDisabled"
                          >دریافت اکسل خدمات</v-btn
                        >
                        <vue-excel-xlsx
                          ref="nurseExcel"
                          v-show="false"
                          :data="excelNurseItems"
                          :columns="
                            role == 'reception' ? recepExcel : adminExcel
                          "
                          :filename="'گزارش خدمات پرستاران'"
                          :sheetname="currentDate"
                        >
                          دریافت اکسل
                        </vue-excel-xlsx>
                      </v-col>
                    </v-row>
                    <div v-if="nurseBusy" class="text-center pa-10">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <h5 class="mt-4 grey--text text--darken-1">
                        در حال بارگذاری...
                      </h5>
                    </div>
                    <div v-else>
                      <report
                        id="nurseReport"
                        v-if="nurseLoaded"
                        :items="nurseReportItems"
                        :job="'nurse'"
                        :isPrinting="isPrinting"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- کارمند پذیرش-->
            <v-tab-item>
              <v-card>
                <v-card-title
                  style="font-size: 18px !important"
                  class="mb-2 primary--text"
                >
                  گزارش خدمات کارمندان پذیرش
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateFrom3">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateFrom3"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateFrom3"
                          element="dateFrom3"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="dateTo3">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateTo3"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateTo3"
                          element="dateTo3"
                          color="#00a7b7"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeFrom3">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="timeFrom"
                            label=" ساعت از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeFrom"
                          element="timeFrom3"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="timeTo3">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="schedule"
                            v-model="timeTo"
                            label=" ساعت تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="timeTo"
                          element="timeTo3"
                          color="#00a7b7"
                          type="time"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-if="!Busy"
                          v-model="receptionName"
                          :items="receptionNames"
                          outlined
                          dense
                          item-text="name"
                          item-value="id"
                          label="نام کارمند پذیرش"
                          class="pa-0"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          class="submit-btn primary-btn"
                          style="float: right"
                          @click="receptionReport"
                          :disabled="receptionName == ''"
                          >اعمال</v-btn
                        >
                      </v-col>
                    </v-row>
                    <hr v-if="receptionLoaded" />
                    <v-row v-if="role == 'manager'" class="align-items-center">
                      <v-icon class="text-danger mb-5"> priority_high </v-icon>
                      <p class="mt-2 ms-2 me-2 text-danger">
                        بازه زمانی انتخابی نمی‌تواند بیشتر از 25 ساعت باشد
                      </p>
                    </v-row>
                    <v-row v-if="receptionLoaded">
                      <v-col>
                        <v-btn
                          class="secondary-btn"
                          @click="printReport('receptionReport')"
                          :disabled="receptionComputedDisabled"
                          >پرینت</v-btn
                        >
                      </v-col>
                      <v-col>
                        <v-btn
                          class="secondary-btn"
                          style="float: left"
                          @click="$refs.recepExcel.$el.click()"
                          :disabled="receptionComputedDisabled"
                          >دریافت اکسل خدمات</v-btn
                        >
                        <vue-excel-xlsx
                          v-show="false"
                          ref="recepExcel"
                          :data="excelReceptionItems"
                          :columns="
                            role == 'reception' ? recepExcel : adminExcel
                          "
                          :filename="'گزارش خدمات کارمند پذیرش'"
                          :sheetname="currentDate"
                        >
                          دریافت اکسل
                        </vue-excel-xlsx>
                      </v-col>
                    </v-row>
                    <div v-if="receptionBusy" class="text-center pa-10">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <h5 class="mt-4 grey--text text--darken-1">
                        در حال بارگذاری...
                      </h5>
                    </div>
                    <div v-else>
                      <report
                        id="receptionReport"
                        v-if="receptionLoaded"
                        :items="recReportItems"
                        :job="'reception'"
                        :isPrinting="isPrinting"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
import report from "../../src/components/report/ByPerson/Report.vue";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    report,
    MobileText,
  },
  data() {
    return {
      tab: null,
      nurseLoaded: false,
      docLoaded: false,
      receptionLoaded: false,
      docReportItems: {},
      nurseReportItems: {},
      recReportItems: {},
      CurrentPage: 1,
      PerPage: 10,
      Busy: false,
      role: "",
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      shiftsList: [],
      shiftsListFields: [
        { key: "index", label: "#" },
        { key: "shiftName", label: "عنوان" },
        { key: "startDate", label: "از" },
        { key: "finishDate", label: "تا" },
        { key: "docName", label: "پزشک" },
        { key: "nurseName", label: "پرستار" },
        { key: "receptionName", label: "کارمند پذیرش" },
        { key: "operation", label: "مدیریت" },
      ],
      recepExcel: [
        { field: "name", label: "خدمت" },
        { field: "count", label: "تعداد" },
        { field: "total", label: "کارکرد درمانگاه" },
        // { field: "totalExpens", label: "هزینه مواد مصرفی" },
        { field: "totalCost", label: "مبلغ دریافتی" },
      ],
      adminExcel: [
        { field: "name", label: "خدمت" },
        { field: "count", label: "تعداد" },
        { field: "total", label: "کارکرد درمانگاه" },
        { field: "totalClinicShare", label: "هزینه اقلام حفاظتی" },
        // { field: "totalExpens", label: "هزینه مواد مصرفی" },
        { field: "totalCost", label: "مبلغ دریافتی" },
      ],
      excelDocItems: [],
      excelNurseItems: [],
      excelReceptionItems: [],
      drName: "",
      nurseName: "",
      receptionName: "",
      receptionNames: [],
      dateFrom: moment(new Date()).subtract(1, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateFrom1: moment(new Date()).subtract(1, "day").format("jYYYY/jMM/jDD"),
      dateTo1: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateFrom2: moment(new Date()).subtract(1, "day").format("jYYYY/jMM/jDD"),
      dateTo2: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateFrom3: moment(new Date()).subtract(1, "day").format("jYYYY/jMM/jDD"),
      dateTo3: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      chartData: {},
      chartOptions: {},
      showChart: false,
      isPrinting: false,
      docBusy: false,
      nurseBusy: false,
      receptionBusy: false,
    };
  },

  methods: {
    shiftList() {
      //NOTE getting all shift list info
      this.Busy = true;
      if (this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/shift/filter",
            {
              startDate: this.dateFrom + this.timeFrom,
              endDate: this.dateTo + this.timeTo,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            if (res.status == 200) {
              this.shiftsList = res.data;
              this.TotalRows = this.shiftsList.length;
              this.CurrentPage = 1;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.Busy = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/shift/filter",
            {
              startDate: this.dateFrom + this.timeFrom,
              endDate: this.dateTo + this.timeTo,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.Busy = false;
            if (res.status == 200) {
              this.shiftsList = res.data;
              this.TotalRows = this.shiftsList.length;
              this.CurrentPage = 1;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.Busy = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      }
    },
    docReport() {
      //NOTE getting all doc report info
      this.docLoaded = false;
      this.docBusy = true;
      if (this.role == "dentistryNurse") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/reports/doctor",
            {
              startDate: this.dateFrom1 + this.timeFrom,
              endDate: this.dateTo1 + this.timeTo,
              userId: this.drName,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.docBusy = false;
            if (res.status == 200) {
              this.docReportItems = res.data;

              this.excelDocItems = this.docReportItems.visits.concat(
                this.docReportItems.takhasos,
                this.docReportItems.balinis,
                this.docReportItems.outBalinis,
                this.docReportItems.surgery,
                this.docReportItems.surgeryOut,
                this.docReportItems.others,
                this.docReportItems.dentistry
              );
              this.docLoaded = true;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.docBusy = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/reports/doctor",
            {
              startDate: this.dateFrom1 + this.timeFrom,
              endDate: this.dateTo1 + this.timeTo,
              userId: this.drName,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.docBusy = false;
            if (res.status == 200) {
              this.docReportItems = res.data;

              this.excelDocItems = this.docReportItems.visits.concat(
                this.docReportItems.takhasos,
                this.docReportItems.balinis,
                this.docReportItems.outBalinis,
                this.docReportItems.surgery,
                this.docReportItems.surgeryOut,
                this.docReportItems.others,
                this.docReportItems.dentistry
              );
              this.docLoaded = true;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.docBusy = false;
          });
      }
    },
    nurseReport() {
      //NOTE getting all nurse report
      this.nurseLoaded = false;
      this.nurseBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/nurse",
          {
            startDate: this.dateFrom2 + this.timeFrom,
            endDate: this.dateTo2 + this.timeTo,
            userId: this.nurseName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.nurseBusy = false;
          if (res.status == 200) {
            this.nurseReportItems = res.data;
            this.excelNurseItems = this.nurseReportItems.balinis.concat(
              this.nurseReportItems.outBalinis,
              this.nurseReportItems.surgery,
              this.nurseReportItems.surgeryOut,
              this.nurseReportItems.homeCare
            );
            this.nurseLoaded = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.nurseBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    receptionReport() {
      //NOTE getting all reception report
      this.receptionLoaded = false;
      this.receptionBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/reports/reception",
          {
            startDate: this.dateFrom3 + this.timeFrom,
            endDate: this.dateTo3 + this.timeTo,
            userId: this.receptionName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.receptionBusy = false;
          if (res.status == 200) {
            this.recReportItems = res.data;
            this.excelReceptionItems = this.recReportItems.visits.concat(
              this.recReportItems.takhasos,
              this.recReportItems.homeCare,
              this.recReportItems.balinis,
              this.recReportItems.outBalinis,
              this.recReportItems.surgery,
              this.recReportItems.surgeryOut,
              this.recReportItems.others
            );
            this.receptionLoaded = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.receptionBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    printReport(div) {
      //NOTE make pdf of doctor report for printing
      this.isPrinting = true;
      // Use nextTick to trigger the print on next update
      this.$nextTick(() => {
        this.$htmlToPaper(div, null, () => {
          this.isPrinting = false; // hide the <p> tag when printing is done
        });
      });
    },
    receptionsList() {
      //NOTE setting receptions name and id by mapping through data
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/receptions",

          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.receptionNames = res.data.filter(
              (x) => x.role != "پذیرش آزمایشگاه"
            );
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["drNames", "nurseNames"]),
    doctorComputedDisabled() {
      if (this.role != "dentistryNurse") {
        if (
          !this.docReportItems.visits.length &&
          !this.docReportItems.takhasos.length &&
          !this.docReportItems.balinis.length &&
          !this.docReportItems.outBalinis.length &&
          !this.docReportItems.surgery.length &&
          !this.docReportItems.surgeryOut.length &&
          !this.docReportItems.others.length
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (!this.docReportItems.dentistry.length) {
          return true;
        } else {
          return false;
        }
      }
    },
    nurseComputedDisabled() {
      if (
        !this.nurseReportItems.balinis.length &&
        !this.nurseReportItems.outBalinis.length &&
        !this.nurseReportItems.surgery.length &&
        !this.nurseReportItems.surgeryOut.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    receptionComputedDisabled() {
      if (
        !this.recReportItems.visits.length &&
        !this.recReportItems.takhasos.length &&
        !this.recReportItems.homeCare.length &&
        !this.recReportItems.balinis.length &&
        !this.recReportItems.outBalinis.length &&
        !this.recReportItems.surgery.length &&
        !this.recReportItems.surgeryOut.length &&
        !this.recReportItems.others.length
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    if (this.role == "reception" && this.role == "dentistryNurse") {
      this.dateFrom = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.dateFrom1 = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.dateFrom2 = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.dateFrom3 = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.timeFrom = moment(new Date()).subtract(13, "hour").format("HH:mm");
      this.timeTo = moment(new Date()).format("HH:mm");
    }
    if (this.role == "admin" || this.role == "manager") {
      this.receptionsList();
    }
    this.shiftList();
  },
};
</script>
